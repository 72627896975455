/* src/components/Hero.css */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;
  background-color: #000; /* Background color */
}

.hero-content-A, .hero-content-B {
  flex: 1; /* Allows the content to grow */
  color: white; /* Set text color for all child text elements */
  margin: 50px 50px;
  padding: 50px 50px;
}

.hero-content-A h2, .hero-content-B h2 {
  font-size: 3rem; /* Larger for impact */
  font-weight: bold;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
}

.hero-content-A h3, .hero-content-B h3 {
  font-size: 1.6rem; /* Subtitle font size */
  margin-bottom: 20px;
  color: #cccccc; /* Softer color for a subtle look */
}

.hero-image {
  flex: 1; /* Image takes up the same space as content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 80%;
  min-width: 200px;
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Fully rounded image */
  object-fit: cover; /* Ensure the image covers the available area nicely */
}

/* For SVG-based icons */
.icon-class img, .icon-class svg {
  width: 30px;
  height: 30px;
  fill: white; /* Change to white for consistency */
  filter: invert(100%) brightness(100%);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: calc(0.1s * var(--icon-index));
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* For Font Awesome icons */
.icon-class-fa {
  font-size: 2em;   /* For Font Awesome or text-based icons */
  color: white;
}

[data-tooltip] {
  position: relative;
  cursor: pointer; /* Change cursor to pointer for icons with tooltips */
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 100; /* Make sure tooltip appears on top */
}

[data-tooltip]:hover:before {
  opacity: 1; /* Show tooltip on hover */
}

/* Responsive adjustments */
@media (min-width: 768px) and (max-width: 1023px) {
  .hero-content {
    margin: 50px 60px;
    padding: 60px 50px;
  }

  .hero-image img {
    height: auto;
    max-width: 80%; /* Slightly smaller image on tablets */
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    flex-direction: column; /* Stack children vertically */
    padding: 20px; /* Reduce padding on smaller screens */
    align-items: center; /* Align children centrally */
    text-align: center; /* Center text alignment */
  }

  .hero-content {
    order: 1; /* Optionally change order if you want text below image */
    margin: 40px 10px;
    padding: 10px 0px 40px 0;
  }

  .hero-image {
    order: 0; /* Image comes first */
    flex: none; /* Override any previous flex settings */
  }

  .hero-image img {
    width: 100%;
    height: auto; /* Ensure the image adapts to screen size */
    border-radius: 20%; /* Less rounded corners on small screens */
    margin-top: 20px;
    padding: 0; /* Remove padding */
    max-width: 100vw; /* Ensure the image doesn’t overflow horizontally */
  }
}
