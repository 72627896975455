/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    color: #333;
    margin-top: 0;
  }
  
  .modal-content p {
    color: #666;
    line-height: 1.6;
  }
  
  /* Image Gallery */
  .image-gallery {
    margin-top: 20px;
    text-align: center;
  }
  
  .image-gallery img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  /* Close Button */
  .modal-content .button-typeA {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-content .button-typeA:hover {
    background-color: #0056b3;
    box-shadow: 2px 2px 1px grey;
    border-color: darkgrey;
    border-style: solid;
    border-width: 1px;
  }

  /* Link Button */
  .modal-content .button-typeB {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .modal-content .button-typeB:hover {
    background-color: rgb(2, 78, 2);
    box-shadow: 2px 2px 1px grey;
    border-color: darkgrey;
    border-style: solid;
    border-width: 1px;
  }
  