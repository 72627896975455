body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 60px;
  font-weight: 700;
}

h2 {
  font-size: 36px;
  font-weight: 600;
}

p, body {
  font-size: 16px;
  line-height: 1.6;
}

button {
  font-size: 18px;
  font-weight: 600;
}

button {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 1200px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 32px;
  }

  body {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 28px;
  }

  body {
    font-size: 14px;
    padding: 0 15px; /* Adds padding to prevent text from touching screen edges */
  }

  .service-box {
    flex-direction: column; /* Stack elements vertically in each box */
    padding: 20px; /* Adjust padding as needed */
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }

  body {
    font-size: 14px;
  }

  .hero-section {
    flex-direction: column;
    text-align: center; /* Center-aligns text for small screens */
  }

  .service-container {
    display: flex;
    flex-direction: column; /* Stacks service boxes vertically */
    gap: 20px;
  }

  .contact-icons {
    justify-content: center; /* Centers contact icons */
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  body {
    font-size: 13px;
  }

  .button {
    font-size: 16px;
    padding: 10px 20px; /* Smaller padding on buttons */
  }

  .service-box {
    padding: 15px; /* Adjust padding for small screens */
  }

  .footer {
    flex-direction: column;
    align-items: center; /* Centers content in footer */
    text-align: center;
  }
}
