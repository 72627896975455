/* CallToAction.css */

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #123456; /* Dark background for high contrast */
  color: white;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

.footer-interior {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.call-to-action {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
}

.call-to-action h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #f0f0f0;
}

/* Call-to-action buttons */
.call-to-action button {
  padding: 12px 24px;
  font-size: 1.1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Collaborate Button - Primary Action */
.call-to-action .collaborate-btn {
  background-color: #ff5722; /* Distinct color for emphasis */
  color: white;
}

.call-to-action .collaborate-btn:hover {
  background-color: #e64a19; /* Darker shade on hover */
  transform: scale(1.05);
}

/* Download CV Button - Secondary Action */
.call-to-action .cv-btn {
  background-color: #4CAF50; /* Different color to set it apart */
  color: white;
}

.call-to-action .cv-btn:hover {
  background-color: #388E3C; /* Darker shade on hover */
  transform: scale(1.05);
}

/* Social icons styling */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px; /* Larger gap for better spacing */
}

.social-icon{
  font-size: 2.2rem; /* Larger icon size */
  color: #8f8f8f;
  position: relative;
  transition: color 0.3s ease;
  padding: 5px;
  border-radius: 10px;
  width: 60px;
}

.social-icon:hover {
  color: #ff5722; /* Highlight color on hover for visibility */
}

/* Tooltips for social icons */
.social-icon :after {
  content: attr(data-tooltip); /* Tooltip content from data attribute */
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.social-icon :hover::after {
  opacity: 1;
}

.call-to-action .collaborate-btn {
  background-color: #ff5722;
  color: white;
}

.call-to-action .cv-btn {
  background-color: #4CAF50;
  color: white;
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }

  .call-to-action h2 {
    font-size: 1.8rem;
  }

  .call-to-action button {
    font-size: 1rem;
  }

  .footer-interior {
    margin-top: 15px;
  }
}
