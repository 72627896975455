/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    background-color: #333; /* Dark background for the header */
    color: white;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.logo a {
    color: white;
    text-decoration: none;
    font-size: 30px;
    margin-left: 20px; /* Additional margin for the logo */
}

.hamburger {
    display: none; /* Hide by default, shown only in mobile view */
    cursor: pointer;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
}

.desktop-nav {
    padding-right: 20px;
    display: flex;
    align-items: center;
}

.nav-link {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
}

.nav-link:hover {
    text-decoration: underline;
}

.cta-button {
    background-color: #007BFF; /* Bright blue background for the CTA link */
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
}

@media (max-width: 600px) {
    .hamburger {
        display: block; /* Show hamburger icon on small screens */
    }

    .desktop-nav {
        display: none; /* Hide desktop navigation on small screens */
    }

    .mobile-nav {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align the mobile navigation */
        background-color: #444; /* Slightly lighter background for mobile nav */
        position: absolute;
        top: 60px;
        width: 100%;
        padding: 10px 0;
    }

    .mobile-nav .nav-link,
    .mobile-nav .cta-button {
        display: block;
        width: 100%;
        text-align: center; /* Center text for mobile nav links */
    }
}
